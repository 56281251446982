import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { LoadSettingsCompanyDetails } from '../actions';

@Injectable({ providedIn: 'root' })
export class SettingsStoreService {
  constructor(private store: Store) {}

  @Dispatch()
  loadSettingsCompanyDetails = () => new LoadSettingsCompanyDetails();
}
