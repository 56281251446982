import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';

import { PROFILE_EMAIL } from '@customer-portal/shared';

import {
  SettingsCompanyDetailsDataDto,
  SettingsCompanyDetailsDto,
} from '../dtos';
import {
  SettingsCompanyDetailsMapperService,
  SettingsCompanyDetailsService,
} from '../services';
import {
  LoadSettingsCompanyDetails,
  LoadSettingsCompanyDetailsSuccess,
} from './actions';

export interface SettingsStateModel {
  legalEntityList: any[];
  parentCompany: any;
}

const defaultState: SettingsStateModel = {
  legalEntityList: [],
  parentCompany: null,
};

@State<SettingsStateModel>({
  name: 'settings',
  defaults: defaultState,
})
@Injectable()
export class SettingsState {
  constructor(
    private readonly settingsCompanyDetailsService: SettingsCompanyDetailsService,
  ) {}

  @Action(LoadSettingsCompanyDetails)
  loadSettingsCompanyDetails(ctx: StateContext<SettingsStateModel>) {
    return this.getSettingsCompanyDetails().pipe(
      tap((data) => {
        ctx.dispatch(
          new LoadSettingsCompanyDetailsSuccess(
            SettingsCompanyDetailsMapperService.mapToSettingsCompanyDetailsModel(
              data,
            ),
          ),
        );
      }),
    );
  }

  @Action(LoadSettingsCompanyDetailsSuccess)
  loadSettingsCompanyDetailsSuccess(
    ctx: StateContext<SettingsStateModel>,
    { companyDetails }: LoadSettingsCompanyDetailsSuccess,
  ) {
    ctx.patchState({
      legalEntityList: companyDetails.legalEntityList,
      parentCompany: companyDetails.parentCompany,
    });
  }

  private getSettingsCompanyDetails(
    email = PROFILE_EMAIL,
  ): Observable<SettingsCompanyDetailsDataDto> {
    return this.settingsCompanyDetailsService
      .getSettingsCompanyDetails(email)
      .pipe(
        map((dto: SettingsCompanyDetailsDto) =>
          dto?.isSuccess && dto?.data
            ? dto.data
            : ({} as SettingsCompanyDetailsDataDto),
        ),
      );
  }
}
