import { SettingsCompanyDetailsDataDto } from '../../dtos';
import { SettingsCompanyDetailsModel } from '../../models';

export class SettingsCompanyDetailsMapperService {
  static mapToSettingsCompanyDetailsModel(
    data: SettingsCompanyDetailsDataDto,
  ): SettingsCompanyDetailsModel {
    return {
      legalEntityList: data?.legalEntities,
      parentCompany: data?.parentCompany,
    };
  }
}
