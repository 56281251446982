import { SettingsCompanyDetailsModel } from '../../models';

export class LoadSettingsCompanyDetails {
  static readonly type = '[Settings] Load Company Details';
}

export class LoadSettingsCompanyDetailsSuccess {
  static readonly type = '[Settings] Load Company Details Success';

  constructor(public companyDetails: SettingsCompanyDetailsModel) {}
}
