import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { SettingsCompanyDetailsDto } from '../../dtos';
import { SETTINGS_COMPANY_DETAILS_QUERY } from '../../graphql';

@Injectable({ providedIn: 'root' })
export class SettingsCompanyDetailsService {
  private clientName = 'contact';

  constructor(private readonly apollo: Apollo) {}

  getSettingsCompanyDetails(
    email: string,
  ): Observable<SettingsCompanyDetailsDto> {
    return this.apollo
      .use(this.clientName)
      .query({
        query: SETTINGS_COMPANY_DETAILS_QUERY,
        variables: {
          email,
        },
      })
      .pipe(map((results: any) => results?.data?.userCompanyDetails));
  }
}
